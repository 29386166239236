import React, { useState } from 'react';
import axios from 'axios';
import './Upload.css'; // Import the CSS file

const Upload = () => {
    const [files, setFiles] = useState({});
    const [messages, setMessages] = useState({});

    const predefinedFilenames = {
        1: 'objectNode1.png',
        2: 'objectNode2.png',
        3: 'objectNode3.png',
        4: 'objectNode4.png',
        5: 'objectNode5.png',
        6: 'objectNode6.png',
        7: 'objectNode7.png',
        8: 'background.png',
        9: 'background1.png',
        10: 'background2.png',
        11: 'background3.png',
        12: 'background4.png',
        13: 'background5.png',
        14: 'background6.png',
        15: 'background7.png',
        16: 'background8.png',
        17: 'objectNode1_red.png',
        18: 'sprite_idle1.png',
        19: 'sprite_idle2.png',
        20: 'sprite_idle3.png',
        21: 'sprite_idle4.png'
    };

    const onFileChange = (e, index) => {
        console.log(`Selected file for ${index <= 7 ? 'Object Node' : 'Background Image'} ${index}:`, e.target.files[0]);
        setFiles(prevFiles => ({ ...prevFiles, [index]: e.target.files[0] }));
    };

    const applyCurrentShade = (canvas, ctx, shadeLevel) => {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        const shadeFactor = 1 - (shadeLevel / 10); // Calculate shade factor

        for (let i = 0; i < data.length; i += 4) {
            const gray = (0.21 * data[i] + 0.72 * data[i + 1] + 0.07 * data[i + 2]) * shadeFactor;
            data[i] = gray;        // red
            data[i + 1] = gray;    // green
            data[i + 2] = gray;    // blue
        }

        ctx.putImageData(imageData, 0, 0);
    };

    const createResizedCanvas = (originalCanvas, scale) => {
        const newCanvas = document.createElement('canvas');
        newCanvas.width = originalCanvas.width * scale;
        newCanvas.height = originalCanvas.height * scale;
        const newCtx = newCanvas.getContext('2d');
        newCtx.drawImage(originalCanvas, 0, 0, newCanvas.width, newCanvas.height);
        return newCanvas;
    };

    const onFileUpload = async (e, index) => {
        e.preventDefault();
        const file = files[index];
        if (!file) {
            setMessages(prevMessages => ({ ...prevMessages, [index]: `Please select a file to upload for ${index <= 7 ? 'Object Node' : 'Background Image'} ${index}.` }));
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = async () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                // Apply the darkening effect
                applyCurrentShade(canvas, ctx, 5); // Adjust the shade level as needed

                // Convert canvas to a Blob for the darkened version
                canvas.toBlob(async (blob) => {
                    const newFileRed = new File([blob], predefinedFilenames[index].replace('.png', '_red.png'), { type: 'image/png' });

                    // Upload the original file
                    const formData = new FormData();
                    formData.append('image', file, predefinedFilenames[index]);

                    try {
                        const res = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });
                        setMessages(prevMessages => ({ ...prevMessages, [index]: res.data.msg }));
                        console.log(`Response for ${index <= 7 ? 'Object Node' : 'Background Image'} ${index}:`, res.data);

                        // Upload the modified (darkened) file
                        const formDataRed = new FormData();
                        formDataRed.append('image', newFileRed, newFileRed.name);

                        try {
                            const resRed = await axios.post(`${process.env.REACT_APP_API_URL}/upload`, formDataRed, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            });
                            setMessages(prevMessages => ({ ...prevMessages, [index]: `Original: ${res.data.msg}, Modified: ${resRed.data.msg}` }));
                            console.log(`Response for modified ${index <= 7 ? 'Object Node' : 'Background Image'} ${index}:`, resRed.data);

                        } catch (err) {
                            if (err.response && err.response.data) {
                                setMessages(prevMessages => ({ ...prevMessages, [index]: `Original: ${res.data.msg}, Modified: ${err.response.data.msg}` }));
                                console.error(`Error response for modified ${index <= 7 ? 'Object Node' : 'Background Image'} ${index}:`, err.response.data);
                            } else {
                                setMessages(prevMessages => ({ ...prevMessages, [index]: `Original: ${res.data.msg}, Modified: An error occurred while uploading the modified file.` }));
                                console.error(`Error uploading modified file for ${index <= 7 ? 'Object Node' : 'Background Image'} ${index}:`, err);
                            }
                        }
                    } catch (err) {
                        if (err.response && err.response.data) {
                            setMessages(prevMessages => ({ ...prevMessages, [index]: err.response.data.msg }));
                            console.error(`Error response for ${index <= 7 ? 'Object Node' : 'Background Image'} ${index}:`, err.response.data);
                        } else {
                            setMessages(prevMessages => ({ ...prevMessages, [index]: 'An error occurred while uploading the file.' }));
                            console.error(`Error uploading file for ${index <= 7 ? 'Object Node' : 'Background Image'} ${index}:`, err);
                        }
                    }
                }, 'image/png');
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    };

    return (
        <div className="upload-container">
            <h1>Upload PNG Images for Object Nodes</h1>
            
            {/* Link to sketch.html */}
            <div className="link-container">
                <a href="sketch.html" className="sketch-link">Go to Sketch</a>
            </div>

            {Array.from({ length: 21 }, (_, i) => (
                <form key={i} onSubmit={(e) => onFileUpload(e, i + 1)} className="upload-form">
                    <label htmlFor={`upload-${i + 1}`} className="upload-label">
                        {i + 1 <= 7 ? `Upload Object Node ${i + 1}:` 
                         : i + 1 <= 17 ? `Upload Background Image ${i - 7}:`
                         : `Upload Sprite Idle ${i - 17}:`}
                    </label>
                    
                    <input
                        type="file"
                        id={`upload-${i + 1}`}
                        accept=".png"
                        onChange={(e) => onFileChange(e, i + 1)}
                        className="upload-input"
                        required
                    />
                    <button type="submit" className="upload-button">Upload</button>
                    {messages[i + 1] && <div className="upload-message">{messages[i + 1]}</div>}
                </form>
            ))}
        </div>
    );
};

export default Upload;
