import React from 'react';

function MainContent() {
    return (
        <div>
            <div className="jumbotron">
                <div className="container">
                    <div className="text-center">
                        <h1>Create a Browser Game in Minutes.</h1>
                        <p>
                        No complex setup.  No rigid templates.  Compiles instantly.
                        </p>
                        <p>
                            <a className="btn btn-primary btn-lg" href="/sketch.html" target="_blank" rel="noopener noreferrer" role="button">
                                Click to get started!
                      
                            </a>
                        </p>
                        <p>    
                            <a className="btn btn-primary btn-lg" href="/workshop.html?template=programBase" target="_blank" rel="noopener noreferrer" role="button">
                                Program from Scratch!
                      
                            </a>
                        </p>
                    </div>
               </div> 
            </div>

           
                     

            <div className="container">
                <div className="row">
                    <h1 className="text-center">Browse Our Templates.</h1>
                    <h4 className="text-center ">Start building today!</h4>
                    <br></br>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-2">
                        <a href="workshop.html?template=systemVisualizer" >
                            <img loading="lazy" className="img-responsive" src="siteImages/system_vis.png" width="167" height="250" alt="system" />
                        </a>
                    </div>
                    <div className="col-md-4">
                            <h3>System Visualizer</h3>
                        <p>
                            <h4>Visualize & Understand Complex Systems.</h4>
                            Visualize and interact with complex systems through connected nodes. 
                            Toggle, adjust, and inspect nodes to gain insights into system behavior and dependencies.
                        </p>
                    </div>

                    <div className="col-md-2">
                         <a href="workshop.html?template=visualLearningNavigator" >
                            <img loading="lazy" className="img-responsive" src="siteImages/visual_learn.png" width="167" height="250" alt="visual" />
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a href="index.html" >
                            <h3>Visual Learning Navigator.</h3>
                        </a>
                        <h4>Convert Any Image into an Interactive Experience</h4>
                        <p>Upload images and place interactive hotspots. Users can click on these hotspots to navigate to other images, 
                            web links, or view detailed information, transforming presentations and training 
                            modules into engaging, interactive experiences.
                        </p>
                    </div>


            <div className="container">
                <div className="row">
                    <div className="col-md-2">
                    <a href="workshop.html?template=assemblyWorkshop" >
                            <img loading="lazy" className="img-responsive" src="siteImages/virtual_assem.png" width="167" height="250" alt="assem" />
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a href="newverse/template_sound/remix.html" >
                            <h3>Virtual Assembly Workshop</h3>
                            <h4>Simulate & Learn Equipment Assembly</h4>
                        </a>
                        <p>
                            Simulate assembly and disassembly of equipment. Drag and place parts within a system, 
                            receiving real-time feedback on correct and incorrect placements.
                        </p>
                    </div>
                    <div className="col-md-2">
                        <a href="workshop.html?template=circuitMaster" >
                            <img loading="lazy" className="img-responsive" src="siteImages/circuit_mas.png"width="167" height="250" alt="circuit" />
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a href="workshop.html?template=circuitMaster" >
                            <h3>Circuit Mastermind.</h3>
                            <h4>Easily Create Interactive Diagrams for Practical Learning</h4>
                        </a>
                        <p>
                            Empower trade school teachers to effortlessly design interactive diagrams where students can virtually 
                            rewrite and reconnect electrical systems, servers, and networking setups. 
                            This template allows students to manipulate connections, fostering hands-on learning and a 
                            deeper understanding of complex systems.
                        </p>
                    </div>
                </div>
                <div className="col-md-2">
                    <a href="workshop.html?template=smartText" >
                            <img loading="lazy" className="img-responsive" src="siteImages/smart_text.png" width="167" height="250" alt="visual" />
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a href="workshop.html?template=smartText" >
                            <h3>Smart Text Explorer.</h3>
                        </a>
                        <h4>Annote any text, including code, manuals, legal documents & more!</h4>
                        <p>Seamlessly input text as bullet points, with detailed annotations appearing upon user interaction. 
                        The built-in RegEx feature highlights key terms, providing in-depth explanations and links to related sections for enhanced understanding.
                        </p>
                    </div>
                    <div className="col-md-2">
                    <a href="workshop_game.html" >
                            <img loading="lazy" className="img-responsive" src="siteImages/traditional_game.png" width="167" height="250" alt="visual" />
                        </a>
                    </div>
                    <div className="col-md-4">
                        <a href="workshop_game.html" >
                            <h3>Traditional Game Maker.</h3>
                        </a>
                        <h4>Make a Traditional Game!</h4>
                        <p>Seamlessly input text as bullet points, with detailed annotations appearing upon user interaction. 
                        The built-in RegEx feature highlights key terms, providing in-depth explanations and links to related sections for enhanced understanding.
                        </p>
                    </div>                    
                </div>
            </div>
            </div>

            <br />
        


            <div className="container">
                <div className="row">
                    <h1 className="text-center">Featured Playgrounds</h1>
                    <h4 className="text-center ">See what others have made with Vearse.</h4>
                    <br></br>
                </div>
            </div>  
            <div className="container">
                    <div className="col-md-2">
                        <a href="HVACWire.html" >
                            <img loading="lazy" className="img-responsive" src="hc_assets/background1.png" width="167" height="250" alt="system" />
                        </a>
                    </div>
                    <div className="col-md-4">
                            <h3>HVAC House Call</h3>
                        <p>
                            <h4>Complete a wiring diagram.</h4>
                            Test your knowledge of HVAC electrical systems
                            Can you fix the housedoor condensor by rewiring a replacement compressor part? 
                        </p>
                    </div>
          
            </div>             
            
            
            <div className="jumbotron">
                <div className="container">
                    <div className="text-center">
                        <h1>Use Your Own Images.</h1>
                        <p>
                            Create Custom Learning Labs using your own images and art.
                            Click here to get started!
                        </p>
                        <p>
                            <a className="btn btn-primary btn-lg" href="/upload"role="button">
                                Upload my Images!
                            </a>
                        </p>
                    </div>
             
                </div>
            </div>

            
                <hr />

                <footer>
                    <br />
                    <p>
                        &copy; 2024 Vearse.com; Marsh Oak Investors
                        <a href="privacy.html">Privacy Policy</a>
                        <br />
                        Legal jargon
                    </p>
                    <p>
                        More legal jargon
                    </p>
                </footer>
            </div>
      
    );
}

export default MainContent;
